<script lang="ts">
    import { page } from '$app/stores';
    import C9Filter from '$lib/components/icons/service-icons/C9Filter.svelte';
    import C9MapBelgium from '$lib/components/icons/service-icons/C9MapBelgium.svelte';
    import { C9_SERVICE_GROUP_PAGES, PAGES_WITHOUT_HEADER } from '$lib/config/config-pages';
    import { t } from '$lib/i18n/i18n-store';
    import { PageViewType } from '$lib/models/page.models';
    import { hasFilter, loadedServiceGroup, pageContentView, showFilter } from '$lib/store/navigation.store';
    import { isStringNotEmpty } from '@rigu/js-toolkit';
    import { onDestroy } from 'svelte';
    import FaThList from 'svelte-icons/fa/FaThList.svelte';

    let displayFilterIcon = false;
    let displayPageHeader = false;

    let filterOpen = false;
    let showTitle = false;
    let titleKey: string | undefined = undefined;

    let showSubtitle = false;
    let subtitleKey: string | undefined = '';

    let viewType: PageViewType | undefined = undefined;

    const unsubscribePage = page.subscribe(pageData => {
        const routeId = pageData.route.id;
        if (!routeId) {
            return;
        }

        if (isStringNotEmpty(routeId)) {
            displayPageHeader = !PAGES_WITHOUT_HEADER.includes(routeId);
            titleKey = Object.keys(C9_SERVICE_GROUP_PAGES).find(key => C9_SERVICE_GROUP_PAGES[key].urlPath === routeId);
            showTitle = isStringNotEmpty(titleKey);
        }

        showSubtitle = isStringNotEmpty(subtitleKey) && !!titleKey && titleKey.includes('various');
    });

    const interceptOpenFilter = (event: MouseEvent) => {
        event?.preventDefault();
        event?.stopPropagation();
        showFilter.set(filterOpen === false);
    };

    const showList = () => {
        pageContentView.set(PageViewType.LIST);
    };

    const showMap = () => {
        pageContentView.set(PageViewType.MAP);
    };

    $: {
        displayFilterIcon = $hasFilter;
        filterOpen = $showFilter;
        subtitleKey = $loadedServiceGroup;
        viewType = $pageContentView;
    }

    onDestroy(() => {
        showFilter.set(false);

        unsubscribePage();

        displayFilterIcon = false;
        displayPageHeader = false;
        filterOpen = false;
        subtitleKey = undefined;
    });
</script>

<style lang="postcss">
    .c9-p-header-subtitle {
        @apply text-gray-700 text-sm;
    }

    .c9-p-filter-btn {
        /*height: ;*/
        @apply flex-grow-0 bg-transparent border border-teal-700 h-8 text-sm text-teal-700 font-bold my-2 py-1 px-2 rounded items-center;
    }

    .c9-content-view-btn {
        @apply h-8 variant-ghost-secondary;
    }

    .c9-content-view-btn.filter-btn-active,
    .c9-p-filter-btn.filter-btn-active {
        @apply bg-teal-900 text-white border-teal-900;
    }

    .c9-p-filter-btn :global(svg) {
        height: 1rem;
        width: 1rem;
        fill: #009394;
        @apply text-teal-800;
    }

    .c9-p-filter-btn.filter-btn-active :global(svg) {
        fill: #FFFFFF;
    }
</style>


{#if displayPageHeader}
    <div id="header-decorator" class="w-full" class:pb-5={viewType !== PageViewType.MAP} class:pb-2={viewType === PageViewType.MAP}>
        <div class="flex flex-row-reverse flex-wrap gap-x-3 align-middle items-center border-b border-teal-900 pb-1 pr-2 border-opacity-20">
            <div class="inline-flex flex-row items-center space-x-2">
                <button id="btn-share" type="button" class="btn btn-sm c9-content-view-btn" class:filter-btn-active={viewType === PageViewType.MAP} on:click={showMap}>
                    <span class="w-[1.3rem] h-[1.3rem]">
                        <C9MapBelgium />
                    </span>
                    <span class="md:inline pl-1 text-xs md:text-base">{$t('label.btn.map')}</span>
                </button>

                <button id="btn-share" type="button" class="btn btn-sm c9-content-view-btn" class:filter-btn-active={viewType === PageViewType.LIST} on:click={showList}>
                    <span class="w-[1.3rem] h-[1.3rem]">
                        <FaThList />
                    </span>
                    <span class="hidden md:inline pl-1">{$t('label.btn.list')}</span>
                </button>

                {#if displayFilterIcon === true }
                    <button id="btn-show-filters" class="btn btn-sm c9-p-filter-btn inline-flex"
                            class:filter-btn-active={filterOpen === true}
                            on:click={interceptOpenFilter}>
                        <C9Filter />
                        <span class="hidden md:inline pl-1">{$t('label.btn.filter')}</span>
                    </button>
                {/if}
            </div>
            <div class="flex-grow px-1 lg:p-0">
                {#if showTitle === true}
                    <h1 class="text-teal-900 text-xl text-opacity-75">{$t(titleKey)}</h1>
                {/if}
                {#if showSubtitle === true}
                    <h2 class="c9-p-header-subtitle">{$t(subtitleKey)}</h2>
                {/if}
            </div>
        </div>
    </div>
{/if}
