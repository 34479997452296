<script lang="ts">
    import { page } from '$app/stores';
    import { t } from '$i18n/i18n-store';
    import { C9_CATEGORY_SEO } from '$lib/config/config-pages';
    import type { PageTagValues } from '$lib/models/page.models';
    import type { ModalSettings } from '@skeletonlabs/skeleton';
    import { getModalStore } from '@skeletonlabs/skeleton';
    import { onDestroy } from 'svelte';
    import IoIosShareAlt from 'svelte-icons/io/IoIosShareAlt.svelte';

    const modalStore = getModalStore();

    let seoValues: PageTagValues;

    const unsubscribePage = page.subscribe((pageData) => {
        const urlHref = pageData.url.href;
        const routeId = pageData.route?.id;
        if (!routeId) {
            return;
        }

        const pageTagValues = C9_CATEGORY_SEO[routeId];

        seoValues = {
            ...(pageTagValues ?? {}),
            seoDescription: pageTagValues?.seoDescription ? encodeURIComponent($t(pageTagValues?.seoDescription)) : '',
            seoTitle: pageTagValues?.seoTitle ? encodeURIComponent($t(pageTagValues?.seoTitle)) : '',
            urlPath: urlHref ? urlHref : '',
        };
    });

    const triggerShare = () => {
        if (seoValues) {
            const modal: ModalSettings = {
                type: 'component',
                component: 'shareModal',
                position: 'item-start',
                meta: { seoValues },
            };
            modalStore.trigger(modal);
        }
    };

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<button id="btn-share" type="button" class="btn btn-sm variant-ghost-primary" on:click={triggerShare}>
    <span class="h-[20px] w-[20px]">
        <IoIosShareAlt/>
    </span>
    <span>{$t('btn.label.share')}</span>
</button>
