<script lang="ts">
    import { t } from '$i18n/i18n-store';
    import { CASA9EU_COOKIE_CONSENT } from '$lib/config';
    import { CookiesConsent } from '$lib/models/cookies.models';
    import { cookiesStore } from '$lib/store/cookies.store';
    import { removeCookie, setCookie, validateAndGetCookiesConsent } from '$lib/toolkit/cookies.utils';
    import { isStringNotEmpty } from '@rigu/js-toolkit';
    import { getModalStore } from '@skeletonlabs/skeleton';
    import Cookies from 'js-cookie';
    import { onMount } from 'svelte';
    import FaPencilRuler from 'svelte-icons/fa/FaPencilRuler.svelte';
    import FaRegChartBar from 'svelte-icons/fa/FaRegChartBar.svelte';
    import FaRegNewspaper from 'svelte-icons/fa/FaRegNewspaper.svelte';
    import MdImportantDevices from 'svelte-icons/md/MdImportantDevices.svelte';

    export let cookies = {};

    const cookieName = CASA9EU_COOKIE_CONSENT;

    const modalStore = getModalStore();

    const cookiesMap: CookiesConsent = new CookiesConsent();

    let showBanner = false;

    $: Object.assign(cookiesMap, cookies);

    const acceptAll = () => {
        cookiesMap.setAll(true);
        setCookie(cookieName, cookiesMap);
        dispatchCookieConsent(cookiesMap);
    };

    const dispatchCookieConsent = (cookieConsent: CookiesConsent) => {
        cookiesStore.set(cookieConsent);
        showBanner = false;
    };

    const necessaryOnly = () => {
        cookiesMap.clearValues();
        setCookie(cookieName, cookiesMap);
        dispatchCookieConsent(cookiesMap);
    };

    const openSettings = () => {
        modalStore.trigger({
            type: 'component',
            component: 'consentCookiesModal',
            meta: cookiesMap,
            response: saveSettings,
        });
    };

    const saveSettings = (cookieConsent: CookiesConsent) => {
        Object.assign(cookiesMap, cookieConsent);
        setCookie(cookieName, cookiesMap);
        dispatchCookieConsent(cookiesMap);
    };

    onMount(() => {
        if (!isStringNotEmpty(cookieName)) {
            throw new Error('Failed to set gdpr cookie, name is missing');
        }

        const cookie: string | undefined = Cookies.get(cookieName);
        if (!cookie) {
            showBanner = true;
            return;
        }

        try {
            const cookieConsent = validateAndGetCookiesConsent(cookie);
            Object.assign(cookiesMap, cookieConsent);
            dispatchCookieConsent(cookieConsent);
        }
        catch (e) {
            removeCookie(cookieName);
            showBanner = true;
            console.error(e);
        }
    });
</script>

{#if showBanner }
    <div class="w-screen h-screen bg-gray-100 bg-opacity-85 flex items-center justify-center fixed z-[999]">
        <section class="m-auto w-11/12 md:w-10/12 lg:w-3/5 bg-white rounded p-[1px]">
            <div class="flex flex-col gap-3 items-center bg-teal-100 bg-opacity-10 border border-primary-400 rounded px-3 py-3 max-h-[95vh] overflow-y-auto text-xs md:text-sm">
                <header class="flex flex-row flex-nowrap items-center gap-x-3 w-full">
                    <img id="nav-logo" class="w-[4rem] inline object-scale-down" alt="www.casa9.eu" src="/images/logo-casa9eu-46px.png" />
                    <h3 class="font-bold">{$t('cookie.gdpr.confidence.title')}</h3>
                </header>
                <div class="md:max-h-[60vh] overflow-y-auto">
                    <div class="grid grid-cols-1 md:grid-cols-2 gap-3">
                        <p class="md:col-span-2 text-pretty">{@html $t('cookie.gdpr.confidence.description')}</p>
                        <div class="grid grid-cols-[15px_auto] gap-3 md:border-r md:border-gray-800 md:pr-2">
                            <span><FaPencilRuler /></span>
                            <span>{$t('cookie.gdpr.list.functionality')}</span>

                            <span><FaRegChartBar /></span>
                            <span>{$t('cookie.gdpr.list.analytics')}</span>

                            <span><MdImportantDevices /></span>
                            <span>{$t('cookie.gdpr.list.development')}</span>

                            <span><FaRegNewspaper /></span>
                            <span>{$t('cookie.gdpr.list.marketing')}</span>

                        </div>
                        <div class="">
                            <p class="indent-5 text-pretty text-justify ">
                                {@html $t('cookie.gdpr.description.p2')}
                            </p>
                            <p class="indent-5 text-justify">
                                {@html $t('cookie.gdpr.description.p3Html')}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="md:px-3 flex flex-col sm:flex-row flex-wrap justify-center gap-x-5 gap-y-2 bg-gray-100">
                    <button class="btn btn-sm variant-filled-secondary" on:click={openSettings}>
                        {$t('btn.cookie.settings')}
                    </button>
                    <button class="btn btn-sm variant-filled-secondary" on:click={necessaryOnly}>
                        {$t('btn.cookie.necessary-only')}
                    </button>
                    <button class="btn btn-sm variant-filled-primary" on:click={acceptAll}>
                        {$t('btn.label.agree')}
                    </button>
                </div>
            </div>
        </section>
    </div>
{/if}
