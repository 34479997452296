<script lang="ts">
    import { t } from '$lib/i18n/i18n-store';
    import { defaultEmpty, isStringNotEmpty } from '@rigu/js-toolkit';
    import { beforeUpdate, createEventDispatcher, onMount } from 'svelte';

    export let isFocused = false;

    let filterText: string;
    let input: HTMLInputElement;
    let prev_filterText: string;

    const dispatch = createEventDispatcher();
    const placeholderText = 'placeholder.search';

    const onChange = (filterValue: string) => {
        dispatch('change', filterValue);
    };

    function handleFocus() {
        isFocused = true;
        if (input) {
            input.focus();
        }
    }

    export function handleClear() {
        filterText = '';
        dispatch('clear', filterText);
        handleFocus();
    }

    beforeUpdate(() => {
        if (defaultEmpty(filterText) !== defaultEmpty(prev_filterText)) {
            if (isStringNotEmpty(filterText)) {
                isFocused = true;
            }
        }
        prev_filterText = filterText;
    });

    onMount(() => {
        if (isFocused) {
            input.focus();
        }
    });
</script>

<style lang="postcss">
    .input-group {
        @apply bg-white grid-cols-[auto_1fr_auto] border-primary-500 border-opacity-50 rounded variant-ringed-primary;
    }

    .input-group-shim {
        @apply px-3 bg-teal-500 bg-opacity-5;
    }
</style>

<div class="input-group ">
    <div class="input-group-shim">
        <i class="fa-solid fa-magnifying-glass text-teal-900" />
    </div>
    <input id="c9-sidebar-filter" type="search" class="py-1"
           bind:this={input}
           bind:value={filterText}
           on:input={() => onChange(input?.value)}
           placeholder={$t(placeholderText)} />
</div>
