<script lang="ts">
    import { t } from '$i18n/i18n-store';
    import CookieSlideButton from '$lib/components/modals/CookieSlideButton.svelte';
    import { CookiesConsent } from '$lib/models/cookies.models';
    import { getModalStore } from '@skeletonlabs/skeleton';
    import { onMount } from 'svelte';

    const modalStore = getModalStore();
    const cookieConsent: CookiesConsent = new CookiesConsent();

    let activeItem: string | undefined = '';
    let responseFn: ((consent: CookiesConsent) => void) | undefined = undefined;

    const respondAndClose = (consent: CookiesConsent) => {
        if (responseFn) {
            responseFn?.(consent);
        }
        modalStore.close();
    };

    function onItemClick(item: string) {
        activeItem = activeItem === item ? undefined : item;
    }

    function necessaryOnly() {
        respondAndClose(new CookiesConsent());
    }

    function saveAll() {
        respondAndClose(new CookiesConsent({ analytics: true, marketing: true, tracking: true }));
    }

    function save() {
        respondAndClose(cookieConsent);
    }

    onMount(() => {
        const modalOptions = $modalStore[0];
        if (modalOptions?.meta) {
            Object.assign(cookieConsent, modalOptions.meta);
            responseFn = modalOptions.response;
        }
    });

</script>

<div class="card w-full max-h-[85vh] md:w-11/12 lg:w-2/3 lg:max-w-[74.25vh] text-sm">
    <header class="card-header flex flex-row items-center py-2">
        <img class="h-6 inline object-scale-down" alt="www.casa9.eu" src="/images/logo-casa9eu-46px.png" />
        <h1 class="pl-1 text-sm font-bold">{ $t('cookie.modal.title') }</h1>
    </header>
    <hr />

    <section class="grid grid-cols-1 sm:grid-cols-2 gap-3 p-4 text-xs h-[55vh] lg:h-auto lg:max-h-[75vh] bg-gray-100 bg-opacity-65 snap-y scroll-smooth overflow-y-auto">
        <div>
            <h3 class="font-bold text-2xs pb-3">
                {$t('cookie.modal.item.info.title')}
            </h3>
            <p class="indent-5 text-justify">{@html $t('cookie.modal.item.info.description1')}</p>
            <p class="indent-5 text-justify">{@html $t('cookie.modal.item.info.description2')}</p>
            <p class="indent-5 text-justify">{@html $t('cookie.modal.item.info.description3')}</p>

            <div class="flex flex-row items-center justify-end gap-3 py-1">
                <p class="grow">{@html $t('cookie.modal.item.more')}</p>
            </div>
        </div>

        <div class="grow flex flex-col gap-1 sm:gap-3 mt-3">
            <CookieSlideButton name="cookie.internal.label"
                               description="cookie.modal.item.internal.description"
                               label="cookie.internal.label"
                               isActive={activeItem === 'internal'}
                               on:click={() => onItemClick('internal')}
                               checked disabled />

            <CookieSlideButton name="cookie.analytics.label"
                               description="cookie.modal.item.analytics.description"
                               label="cookie.analytics.label"
                               isActive={activeItem === 'analytics'}
                               on:click={() => onItemClick('analytics')}
                               bind:checked={cookieConsent.analytics} />

            <CookieSlideButton name="cookie.tracking.label"
                               description="cookie.modal.item.tracking.description"
                               label="cookie.tracking.label"
                               isActive={activeItem === 'tracking'}
                               on:click={() => onItemClick('tracking')}
                               bind:checked={cookieConsent.tracking} />

            <CookieSlideButton name="cookie.marketing.label"
                               description="cookie.modal.item.marketing.description"
                               label="cookie.marketing.label"
                               isActive={activeItem === 'marketing'}
                               on:click={() => onItemClick('marketing')}
                               bind:checked={cookieConsent.marketing} />
        </div>
    </section>

    <hr />
    <footer class="card-footer flex flex-col sm:flex-row gap-3 md:gap-x-7 pt-5">
        <button id="btn" class="btn btn-sm w-full md:w-auto variant-ghost-primary" on:click={saveAll}>
            {$t('btn.cookie.accept-all')}
        </button>
        <button class="btn btn-sm w-full md:w-auto variant-ghost-secondary" on:click={necessaryOnly}>
            {$t('btn.label.reject')}
        </button>
        <div class="grow md:flex md:justify-end">
            <button class="flex-none w-full md:w-auto btn btn-sm variant-ghost-surface" on:click={save}>
                {$t('btn.settings.save')}
            </button>
        </div>
    </footer>
</div>
