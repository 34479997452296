<script lang="ts">
    /* eslint-disable */
    // https://dikshantraj2001.medium.com/setup-google-analytics-4-with-sveltekit-1-0-64d4cbfb8b8d
    import { page } from '$app/stores';
    import { GA_MEASUREMENT_ID } from '$lib/config';
    import { analyticsStore } from '$lib/store/analytics.store';
    import { onDestroy } from 'svelte';

    $: {
        // @ts-ignore
        if (typeof gtag !== 'undefined') {
            gtag('config', GA_MEASUREMENT_ID, {
                page_title: document.title,
                page_path: $page.url.pathname,
            });
        }
    }
    // subscribe to store and see if there is any event in store(array) then run that event
    const analyticsStoreUnsubscribe = analyticsStore.subscribe((queue) => {
        let next = queue && queue.length && queue.shift();
        let retries = 3;
        let previousExecutedOperationId;

        while (next) {
            const { type, event, data, id } = next;
            if (!type || !event || !data || !id) {
                console.error('Incorrect analytics event data');
                next = queue.shift();
                continue;
            }
            // if current id is not equal to previous id then reassign retries to 3
            if (id && id !== previousExecutedOperationId) { retries = 3;}
            previousExecutedOperationId = id;
            // @ts-ignore
            if (typeof gtag !== 'undefined') {
                // @ts-ignore
                gtag(type, event, data);
                // console.log('event created with data', next);
            }
            else {
                // gtag not found, retry till retries become 0
                if (retries > 0) {
                    retries--;
                    continue;
                }
                else {
                    console.error('Analytics event failed to reach server with: ', next);
                }
            }
            next = queue.shift();
        }
    });

    onDestroy(() => (analyticsStoreUnsubscribe()));
</script>

<svelte:head>
    <!-- Google tag (gtag.js) -->
    <script async src="https://www.googletagmanager.com/gtag/js?id=G-XMQE8CLRZ3"></script>
    <script lang="ts">
        try {
            if (typeof window !== 'undefined' && window) {
                window.dataLayer = window.dataLayer || [];

                function gtag() {
                    dataLayer.push(arguments);
                }

                gtag('js', new Date());
                gtag('config', 'G-XMQE8CLRZ3');
            }
            // console.log('Google Analytics setup successfully');
        }
        catch (err) {
            console.error('Error setting up google analytics ', err);
        }
    </script>
</svelte:head>
