<script lang="ts">
    import { t } from '$i18n/i18n-store';
    import { SlideToggle } from '@skeletonlabs/skeleton';
    import MdExpandLess from 'svelte-icons/md/MdExpandLess.svelte';
    import MdExpandMore from 'svelte-icons/md/MdExpandMore.svelte';

    export let checked = false;
    export let disabled = false;
    export let isActive: boolean = false;
    export let name: string | undefined = undefined;
    export let description: string | undefined = undefined;
    export let label: string | undefined = undefined;
</script>

<div class="w-full flex flex-row items-center pr-2 py-2 border rounded-md border-gray-600 {isActive ? 'font-bold bg-surface-200 rounded-md' : ''}">
    <div class="grow flex flex-row items-center ml-2">
        <button type="button" class="btn-icon-sm rounded-3xl bg-surface-100-800-token h-7 w-7 mr-3" on:click>
            <span class=" inline-block">
                {#if isActive}
                    <MdExpandLess />
                {:else}
                    <MdExpandMore />
                {/if}
            </span>
        </button>
        <p>{$t(label ?? '')}</p>
    </div>
    <SlideToggle class="focus:outline-0" name={$t(name ?? '')} bind:checked={checked} {disabled} active="bg-teal-800" size="sm" />
</div>
{#if isActive}
    <div class=" w-full indent-5 text-justify">
        <p class="text-xs">{$t(description ?? '')}</p>
    </div>
{/if}
