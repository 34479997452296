<script lang="ts">
    import type { PageTagValues } from '$lib/models/page.models';
    import { SOCIAL_NETWORK_PROPERTIES } from '$lib/models/social-network.models';
    import { isMobileOrTablet } from '$lib/toolkit/html.utils';

    /**
     * @type { 'email' | 'facebook' | 'instagram' | 'itch.io' | 'mailto' | 'rss' | 'telegram' | 'twitter' | 'whatsapp'}
     */
    export let network: keyof typeof SOCIAL_NETWORK_PROPERTIES;
    export let seoValues: PageTagValues;

    const fgColor = 'transparent';
    let networkProperties = SOCIAL_NETWORK_PROPERTIES[network];

    let description = seoValues?.seoDescription;
    let title = seoValues?.seoTitle;

    let bgColor: string;

    let href = networkProperties?.href;
    let label = networkProperties?.label;
    let ariaLabel = title ?? '';

    let icon: string;
    let mask: string;

    // if (network === 'whatsapp') {
    //     href = browser && isMobileOrTablet() ? 'https://api.whatsapp.com/send' : 'https://web.whatsapp.com/send';
    //     href += '?text=' + title + encodeURIComponent(' ') + seoValues.urlPath;
    // } else {
    //     href = href.replace('{DESCRIPTION}', description)
    //         .replace('{TITLE}', title)
    //         .replace('{URL}', seoValues.urlPath);
    // }

    $: {
        networkProperties = SOCIAL_NETWORK_PROPERTIES[network];
        const color = networkProperties.color;

        icon = networkProperties.icon;
        mask = networkProperties.mask;

        description = seoValues?.seoDescription;
        title = seoValues?.seoTitle;
        bgColor = color;

        href = networkProperties?.href;
        label = networkProperties?.label;
        ariaLabel = title ?? '';

        href = href.replace('{DESCRIPTION}', description)
            .replace('{TITLE}', title)
            .replace('{URL}', encodeURIComponent(seoValues?.urlPath ?? ''));
    }

    const onClick = (event: MouseEvent) => {
        if (networkProperties.dialogue && !isMobileOrTablet()) {
            event?.preventDefault();
            event?.stopPropagation();
            const winTop = (screen.height / 2);
            const winLeft = (screen.width / 2);
            window.open(href, 'sharer', 'noopener,noreferrer,popup=true,screenY=' + winTop + ',screenX=' + winLeft + ',toolbar=0,status=0,width=520,height=350');
        }

        return true;
    };
</script>

<style lang="postcss">
    .c9-social-icon {
        @apply inline-block relative overflow-hidden;
    }

    .c9-social-svg {
        border-radius: 50%;
        fill-rule: evenodd;
    }

    .c9-social-svg-background {
        fill: transparent;
    }

    @media (prefers-reduced-motion: no-preference) {
        .c9-social-svg-mask,
        .c9-social-svg-background {
            --ms-transition: 'fill 170ms ease-in-out';
            -o-transition: 'fill 170ms ease-in-out';
            --moz-transition: 'fill 170ms ease-in-out';
            --webkit-transition: 'fill 170ms ease-in-out';
            transition: 'fill 170ms ease-in-out';
        }
    }
</style>

{#if network !== 'clipboard'}
    <a class="flex flex-col items-center justify-center no-underline mx-5 w-[4rem]" {href} on:click={onClick} target="_blank" rel="noopener noreferrer" aria-label={ariaLabel}>
    <span class="c9-social-icon h-[32px] w-[32px] md:h-[48px] md:w-[48px]">
      <span class="social-container absolute inset-0 w-full h-full">
          <svg class="c9-social-svg absolute inset-0 w-full h-full" viewBox="0 0 64 64">
              <g class="c9-social-svg-background"><circle cx="32" cy="32" r="31" /></g>
              <g class="social-svg-icon" fill={fgColor}>
                  <path d={icon} />
              </g>
              <g class="c9-social-svg-mask" fill={bgColor}><path d={mask} /></g>
          </svg>
      </span>
    </span>
        <p class="text-xs py-2">{label}</p>
    </a>
{/if}
