<script lang="ts">
    import { sidebarIcons } from '$lib/components/C9Icon/icons';
    import { C9_SERVICE_GROUP_PAGES } from '$lib/config/config-pages';
    import { SERVICES_CATEGORIES } from '$lib/config/config-service-list';
    import { t } from '$lib/i18n/i18n-store';
    import { CategoryGroup, SERVICE_GROUP_TYPES } from '$lib/models/category.models';
    import { asArray, defaultEmpty, isNotEmpty, isStringNotEmpty } from '@rigu/js-toolkit';
    import { getModalStore } from '@skeletonlabs/skeleton';

    // Local
    const modalStore = getModalStore();
    const serviceGroupList: CategoryGroup[] = SERVICE_GROUP_TYPES.map(
        (categoryType: string) => SERVICES_CATEGORIES[categoryType],
    );
    let serviceGroupsToShow = serviceGroupList;

    let searchTerm = '';
    let filterValues: string[] = [];
    let highlightResults = false;

    // Elements
    let elemDocSearch: HTMLElement;

    $: highlightResults = filterValues?.length > 0;

    function filterCategories() {
        filterValues = defaultEmpty(searchTerm).split(' ')
            .map(token => token.trim())
            .filter(token => isStringNotEmpty(token) && token.length > 2);

        if (isNotEmpty(filterValues)) {
            serviceGroupsToShow = serviceGroupList.filter(group => (group as CategoryGroup).hasToken(filterValues));
            serviceGroupsToShow.forEach(group => (group.categories = group.categories.filter(category => category.hasToken(filterValues))));
        } else {
            resetFilter();
        }
    }

    function resetFilter() {
        serviceGroupList.forEach(category => (category as CategoryGroup).resetLastTokenExists());
        serviceGroupsToShow = serviceGroupList;
    }

    function onKeyDown(event: KeyboardEvent): void {
        if (['Enter', 'ArrowDown'].includes(event.code)) {
            const queryFirstAnchorElement = elemDocSearch.querySelector('a');
            if (queryFirstAnchorElement) {
                queryFirstAnchorElement.focus();
            }
        }
    }
</script>

<style lang="postcss">
     .c9-search-result-highlight {
        @apply text-teal-900 text-opacity-100 font-extralight;
    }
</style>

<div bind:this={elemDocSearch} class="modal-search card bg-neutral-50 backdrop-blur-sm overflow-hidden max-w-[400px] shadow-xl mt-2 mb-auto pb-3 lg:max-w-[800px]">
    <!-- Header -->
    <header class="modal-search-header bg-blue-200 flex items-center">
        <i class="fa-solid fa-magnifying-glass text-xl ml-4"/>
        <input class="bg-transparent border-0 ring-0 w-full p-4 placeholder-gray-900 placeholder-opacity-60 text-lg focus:ring-0 focus:outline-0 focus:outline-none"
               bind:value={searchTerm} type="search"
               placeholder={$t('placeholder.search')}
               on:input={filterCategories}
               on:keydown={onKeyDown}/>
    </header>

    <!-- Results -->
    {#if serviceGroupsToShow.length > 0}
        <nav class="list-nav overflow-x-auto max-h-[480px] hide-scrollbar" tabindex="-1">
            {#each serviceGroupsToShow as group, i (group.type)}
                <div class="flex justify-start text-sm font-bold px-4 pt-4 pb-2">
                    <span class="s-group-icon-c">
                        {@html sidebarIcons[group.type]}
                    </span>
                    <span class="ml-2 pt-1">{ $t(group.name) }</span>
                </div>
                <ul class="pl-12">
                    {#each group.categories as category, j (category.name)}
                        <li class="text-sm pl-0.5">
                            <a class="!rounded-none justify-between hover:variant-soft focus:!variant-filled-primary outline-0"
                               href={C9_SERVICE_GROUP_PAGES[category.name].urlPath}
                               on:click={() => { modalStore.close(); }}>
                                <div class="flex items-center gap-4" class:c9-search-result-highlight={highlightResults}>
                                    <span class="flex-auto font-bold opacity-75">{$t(category.name)}</span>
                                </div>
                            </a>
                        </li>
                    {/each}
                </ul>
            {/each}
        </nav>
    {:else}
        <div class="p-4">
            <p>Nu s-au gasit rezultate pentru <code class="code">{searchTerm}</code>.</p>
        </div>
    {/if}
    <!-- Footer -->
    <!--    <footer class="modal-search-footer {cFooter}">-->
    <!--        <div><kbd class="kbd">Esc</kbd> inchide</div>-->
    <!--        <div><kbd class="kbd">Tab</kbd> navigare</div>-->
    <!--        <div><kbd class="kbd">Enter</kbd> selectare</div>-->
    <!--    </footer>-->
</div>
