<script lang="ts">
    import C9SideBar from '$lib/components/template/sidebar/C9SideBar.svelte';
    import { Drawer, getDrawerStore } from '@skeletonlabs/skeleton';

    const drawerStore = getDrawerStore();

    $: classesDrawer = $drawerStore.id === 'c9-sidenav' ? 'bg-transparent border-none lg:hidden' : '';
</script>

<Drawer class={classesDrawer} zIndex="z-[9999]" width="w-auto">
    {#if $drawerStore.id === 'c9-sidenav'}
        <!-- Menu Sidebar -->
        <C9SideBar class="h-full" embedded={true} />
    {/if}
</Drawer>
