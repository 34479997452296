import { CookiesConsent } from '$lib/models/cookies.models';
import { asArray, isStringNotEmpty } from '@rigu/js-toolkit';
import Cookies from 'js-cookie';

export const createCookie = (name: string, value: string, days: number) => {
    let expires = '';
    const date = new Date();

    if (days) {
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = ', expires=' + date.toUTCString();
    }
    document.cookie = name + '=' + value + expires + ', path=/';
};

export const readCookie = (name: string) => {
    const nameEQ = name + '=';
    const cookies = document.cookie.split(',');
    const cookie = asArray(cookies).find(cookie => cookie.indexOf(nameEQ) >= 0) ?? '';
    return isStringNotEmpty(cookie) ? cookie.substring(nameEQ.length, cookie.length) : null;
};

export const eraseCookie = (name: string) => {
    createCookie(name, 'false', -999);
};

export const removeCookie = (cookieName: string, path?: string) => {
    Cookies.remove(cookieName, Object.assign({}, path ? { path } : {}));
};

export const setCookie = (cookieName: string, cookieValue: any, cookieConfig: Cookies.CookieAttributes = { sameSite: 'strict' }) => {
    const expires = new Date();
    expires.setDate(expires.getDate() + 365);

    const options = Object.assign({}, cookieConfig, { expires });
    Cookies.set(cookieName, JSON.stringify({ cookieValue }), options);
};

export const validateCookiesConsent = (cookie: any): boolean => {
    const modelKeys = Object.keys(new CookiesConsent({}));
    const cookiesKeys = Object.keys(cookie);

    return modelKeys.length === cookiesKeys.length && modelKeys.every(key => cookiesKeys.includes(key));
};
export const validateAndGetCookiesConsent = (cookie: string): CookiesConsent => {
    const cookieObj = cookie ? JSON.parse(cookie) : {};
    const cookiesConsent = cookieObj.cookieValue ?? cookieObj;

    if (!validateCookiesConsent(cookiesConsent)) {
        throw new Error('cookie consent has changed');
    }

    return new CookiesConsent(cookiesConsent);
};
