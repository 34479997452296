<script lang="ts">
    import { browser } from '$app/environment';
    import ShareButtons from '$lib/components/share-buttons/ShareButton.svelte';
    import { showSideBar } from '$lib/store/navigation.store';

    // Types
    import type { DrawerSettings, ModalSettings } from '@skeletonlabs/skeleton';
    // Components & Utilities
    // Stores
    import { AppBar, getDrawerStore, getModalStore } from '@skeletonlabs/skeleton';
    import { onDestroy, onMount } from 'svelte';
    import type { Unsubscriber } from 'svelte/store';

    const drawerStore = getDrawerStore();

    // Local
    let isOsMac = false;
    let listOpen = true;
    let searchOpen = false;
    let isDrawSidebar = false;
    let unsubscribeShowSidebar: Unsubscriber | undefined = undefined;

    let screenSize = '';

    const sidebarDrawerSettings: DrawerSettings = {id: 'c9-sidenav'};
    const modalStore = getModalStore();

    // Set Search Keyboard Shortcut
    if (browser) {
        let os = navigator.userAgent;
        isOsMac = os.search('Mac') !== -1;
    }

    // Drawer Handler
    function drawerOpen(): void {
        showSideBar.set(true);
        drawerStore.open(sidebarDrawerSettings);
        isDrawSidebar = true;

        if (!unsubscribeShowSidebar) {
            unsubscribeShowSidebar = showSideBar.subscribe((show: boolean) => {
                if (!show) {
                    drawerStore.close();
                }
            });
        }
    }

    // Search button handler
    const triggerSearch = () => {
        searchOpen = searchOpen === false;
        listOpen = searchOpen === true;

        const modal: ModalSettings = {
            type: 'component',
            component: 'searchModal',
            position: 'item-start',
        };
        modalStore.trigger(modal);
    };

    // Keyboard Shortcut (CTRL/⌘+K) to Focus Search
    function onWindowKeydown(e: KeyboardEvent): void {
        if ((e.metaKey || e.ctrlKey) && e.key === 'k') {
            // Prevent default browser behavior of focusing URL bar
            e.preventDefault();
            // If modal currently open, close modal (allows to open/close search with CTRL/⌘+K)
            $modalStore.length ? modalStore.close() : triggerSearch();
        }
    }

    onDestroy(() => {
        if (unsubscribeShowSidebar) {
            unsubscribeShowSidebar();
        }
    });

    onMount(() => {
        screenSize = (window?.screen?.availWidth ?? '') + ' x ' + (window?.screen?.availHeight ?? '');
    });
</script>

<style lang="postcss">
    @media (min-width: 1025px) {
        .c9-search-btn {
            width: 250px;
        }
    }
</style>

<!-- NOTE: using stopPropagation to override Chrome for Windows search shortcut -->
<svelte:window on:keydown|stopPropagation={onWindowKeydown}/>

<AppBar class="text-teal-900" padding="px-1 pr-3 lg:px-2" background="bg-gray-200">
    <svelte:fragment slot="lead">
        <div class="flex items-center space-x-4">
            <!-- Hamburger Menu -->
            <button on:click={drawerOpen} class="btn-icon btn-icon-sm lg:!hidden">
                <i class="fa-solid fa-bars text-xl"/>
            </button>
            <!-- Logo -->
            <a class="no-underline hover:no-underline lg:!ml-0 w-auto overflow-hidden" href="/">
                <img id="nav-logo" class="c9-logo w-[7rem] md:w-[9.375rem] inline object-scale-down" alt="www.casa9.eu" src="/images/logo-casa9eu-46px.png"/>
            </a>
        </div>
    </svelte:fragment>
    <svelte:fragment slot="trail">
        <!-- Search-->
        <div class="flex md:inline-flex items-center md:ml-4">
            <!--            <span class="py-3">-->
            <!--                {screenSize}-->
            <!--            </span>-->
            <ShareButtons/>
            <div class="c9-search-btn pl-2 xl:pl-3 xl:pr-0 xl:-ml-3">
                <div class="w-full xl:pl-4">
                    <button class="btn btn-sm w-full justify-start space-x-3.5 variant-ringed-primary hover:variant-ringed-primary" on:click={triggerSearch}>
                        <i class="fa-solid fa-magnifying-glass text-sm"/>
                        <small class="hidden md:inline-block">{isOsMac ? '⌘' : 'Ctrl'}+K</small>
                    </button>
                </div>
            </div>
        </div>
    </svelte:fragment>
</AppBar>
