export class CookiesConsent {
    analytics = false;
    marketing = false;
    tracking = false;

    readonly necessary = true;

    constructor(values: Partial<CookiesConsent> = {}) {
        Object.assign(this, values);
    }

    public clearValues() {
        this.setAll(false);
    }

    public setAll(value: boolean) {
        this.analytics = value;
        this.marketing = value;
        this.tracking = value;
    }

    public setValues(values: Partial<CookiesConsent> = {}) {
        Object.assign(this, values);
    }
}
