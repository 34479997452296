<script lang="ts">
    import { goto } from '$app/navigation';
    import { page } from '$app/stores';
    import { sidebarIcons } from '$lib/components/C9Icon/icons';
    import C9IconInfo from '$lib/components/icons/service-icons/C9IconInfo.svelte';
    import C9IconProviders from '$lib/components/icons/service-icons/C9IconProviders.svelte';
    import MenuItem from '$lib/components/template/MenuItem.svelte';
    import C9SideBarFilter from '$lib/components/template/sidebar/C9SideBarFilter.svelte';
    import SideBarCategory from '$lib/components/template/sidebar/SideBarCategory.svelte';
    import { APP_RAIL_PROPERTIES, APP_RAIL_TYPE, C9_SERVICE_GROUP_PAGES, GROUP_PAGES, INFO_PAGES } from '$lib/config/config-pages';
    import { SERVICES_CATEGORIES } from '$lib/config/config-service-list';
    import { t } from '$lib/i18n/i18n-store';
    import type { CategoryGroup } from '$lib/models/category.models';
    import { SERVICE_GROUP_TYPES } from '$lib/models/category.models';
    import { defaultEmpty, isNotEmpty, isStringNotEmpty } from '@rigu/js-toolkit';
    import { Accordion, AccordionItem, AppRail, AppRailAnchor, AppRailTile, getDrawerStore } from '@skeletonlabs/skeleton';
    import { onDestroy } from 'svelte';
    import IoIosHome from 'svelte-icons/io/IoIosHome.svelte';
    import type { Writable } from 'svelte/store';
    import { writable } from 'svelte/store';

    // Local
    let currentRailCategory: string | null = APP_RAIL_TYPE.providers;
    const serviceGroupList: CategoryGroup[] = SERVICE_GROUP_TYPES.map(
        (categoryType: string) => SERVICES_CATEGORIES[categoryType],
    );
    let serviceGroupsToShow = serviceGroupList;

    // Local
    const active: Writable<string | null> = writable(null);
    const highlight: Writable<string | null> = writable(null);

    const drawerStore = getDrawerStore();

    const filterCategories = (onChangeValue: CustomEvent<string>) => {
        const filterValues = defaultEmpty(onChangeValue.detail).split(' ')
            .map(token => token.trim())
            .filter(token => isStringNotEmpty(token) && token.length > 2);

        if (isNotEmpty(filterValues)) {
            serviceGroupsToShow = serviceGroupList.filter(category => (category as CategoryGroup).hasToken(filterValues));
        }
        else {
            resetFilter();
        }
    };

    const resetFilter = (): void => {
        serviceGroupList.forEach(category => (category as CategoryGroup).resetLastTokenExists());
        serviceGroupsToShow = serviceGroupList;
    };

    const onClickAnchor = (): void => {
        currentRailCategory = null;
        drawerStore.close();
    };

    const onTogglePage = (): void => {
        drawerStore.close();
    };

    const onToggleServiceGroup = (serviceGroup: CategoryGroup) => {
        active.set(serviceGroup.type);
        if (serviceGroup.single) {
            const category = serviceGroup.categories[0];
            goto(C9_SERVICE_GROUP_PAGES[category.name].urlPath);
            onTogglePage();
        }
    };

    const unsubscribePage = page.subscribe(pageData => {
        const basePath = pageData.url.pathname.split('/')[1];
        if (!isStringNotEmpty(basePath)) {
            active.set(null);
            highlight.set(null);
            return;
        }

        const groupType = GROUP_PAGES[basePath as string];

        highlight.set(groupType);
        currentRailCategory = groupType ? APP_RAIL_TYPE.providers : APP_RAIL_TYPE.info;
    });

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<style lang="postcss">
    .c9-s-bar-container {
        @apply bg-gray-300 bg-opacity-100 rounded-none;
    }

    .c9-s-bar-container :global(.accordion-item) {
        @apply border-b border-gray-500 border-opacity-25;
    }

    .c9-s-bar-container :global(.accordion-item .accordion-summary) {
        @apply ml-2;
    }

    :global(.s-group-icon-c) {
        @apply flex justify-center;
    }

    :global(.s-group-icon-c svg) {
        @apply w-8 h-8;
    }

    .c9-s-bar-container :global(.s-group-name) {
        @apply font-comforta text-sm cursor-pointer;
    }

    .c9-s-bar-container :global(.s-group-name:hover) {
        @apply opacity-100;
    }

    :global(.c9-bar-accordion) {
        scrollbar-width: thin;
    }

    :global(.s-group-link) {
        @apply flex items-center text-left pr-1 pl-3 py-3 cursor-pointer text-black rounded-none;
    }

    :global(.s-group-link:hover),
    :global(.s-group-open .s-group-link),
    :global(.s-group-highlight .s-group-link) {
        @apply bg-transparent text-teal-900 text-opacity-100 pl-3 !important;
        @apply border-l-4 border-teal-700;
    }

    :global(.s-group-highlight .s-group-link) {
        @apply font-bold;
    }

    :global(.c9-rail-lead) {
        @apply flex justify-center;
    }

    :global(.c9-rail-lead svg) {
        @apply h-9 w-9 !important;
    }

    @media (min-width: 835px) {
        .c9-s-bar-container {
            @apply shadow-xl bg-teal-800 rounded-tr-lg z-auto;
            --tw-bg-opacity: 0.15;
        }

        .c9-s-bar-container :global(.accordion-item) {
            @apply border-none;
        }

        :global(.s-group-icon-c svg) {
            @apply w-6 h-6 !important;
        }

        :global(.s-group-link) {
            @apply text-opacity-75 p-1 pl-3;
        }
    }
</style>

<div class="c9-s-bar-container grid grid-cols-[auto_2fr] {$$props.class ?? ''}">
    <!-- App Rail -->
    <AppRail background="bg-transparent" border="border-r border-surface-500/20">
        <!-- Mobile Only -->
        <!-- prettier-ignore -->
        <AppRailAnchor href="/" class="lg:hidden" regionLead="c9-rail-lead" on:click={() => { onClickAnchor() }}>
            <svelte:fragment slot="lead">
                <IoIosHome class="w-8 h-8" />
            </svelte:fragment>
        </AppRailAnchor>
        <hr class="opacity-30" />

        <!-- prettier-ignore -->
        <AppRailTile bind:group={currentRailCategory} name={$t(APP_RAIL_PROPERTIES.providers.name)} value={APP_RAIL_TYPE.providers} regionLead="c9-rail-lead">
            <svelte:fragment slot="lead">
                <C9IconProviders name="info" />
            </svelte:fragment>
            <span>{$t(APP_RAIL_PROPERTIES.providers.name)}</span>
        </AppRailTile>

        <!-- prettier-ignore -->
        <AppRailTile bind:group={currentRailCategory} name={$t(APP_RAIL_PROPERTIES.info.name)} value={APP_RAIL_TYPE.info} regionLead="c9-rail-lead">
            <svelte:fragment slot="lead">
                <C9IconInfo name="info" />
            </svelte:fragment>
            <span>{$t(APP_RAIL_PROPERTIES.info.name)}</span>
        </AppRailTile>
    </AppRail>

    <!-- Nav Links -->
    <section class="h-full flex flex-col w-[15.315rem] overflow-y-hidden">
        {#if currentRailCategory === APP_RAIL_TYPE.info}
            <div class="px-5 py-3">
                <h1 class="font-bold opacity-75 pb-3 text-xl">{$t('nav.information')}</h1>
                {#each INFO_PAGES as link, i (link.name)}
                    <p class="py-1">
                        <MenuItem class="text-black" on:selectRoute={onTogglePage} {link} />
                    </p>
                {/each}
            </div>
        {/if}
        {#if currentRailCategory === APP_RAIL_TYPE.providers}
            <div class="hidden lg:block lg:p-3">
                <C9SideBarFilter on:change={filterCategories} on:clear={filterCategories} />
            </div>

            <Accordion autocollapse class="overflow-y-auto c9-bar-accordion" regionControl="s-group-link" regionCaret="hidden" regionPanel="pl-0 pt-0 border-l border-transparent">
                {#each serviceGroupsToShow as serviceGroup, i (serviceGroup.type)}
                    <AccordionItem id="{serviceGroup.type}" {active}
                                   class="s-group {$active === serviceGroup.type ? 's-group-open' : '' } {$highlight === serviceGroup.type ? 's-group-highlight' : ''}"
                                   on:toggle={() => onToggleServiceGroup(serviceGroup)}
                                   open="{$highlight === serviceGroup.type}">
                        <svelte:fragment slot="lead">
                            <span class="s-group-icon-c">{@html sidebarIcons[serviceGroup.type]}</span>
                        </svelte:fragment>
                        <svelte:fragment slot="summary">
                            <span class="s-group-name">{ $t(serviceGroup.name)}</span>
                        </svelte:fragment>

                        <svelte:fragment slot="content">
                            {#if !serviceGroup.single}
                                <div class="relative block group-hover:block">
                                    {#each serviceGroup.categories as category, j (category.name)}
                                        <SideBarCategory {category} />
                                    {/each}
                                </div>
                            {/if}
                        </svelte:fragment>
                    </AccordionItem>
                {/each}
            </Accordion>
        {/if}
    </section>
</div>
