<script lang="ts">
    import { page } from '$app/stores';
    import { sidebarIcons } from '$lib/components/C9Icon/icons';
    import { C9_SERVICE_GROUP_PAGES } from '$lib/config/config-pages';
    import { t } from '$lib/i18n/i18n-store';
    import type { ServiceCategoryModel } from '$lib/models/category.models';
    import { showSideBar } from '$lib/store/navigation.store';
    import { isStringNotEmpty } from '@rigu/js-toolkit';
    import { getDrawerStore } from '@skeletonlabs/skeleton';
    import { onDestroy } from 'svelte';

    export let category: ServiceCategoryModel;

    let isActive = false;
    let urlPath = C9_SERVICE_GROUP_PAGES[category.name].urlPath;
    let icon = sidebarIcons[category.name];
    let isSelected = false;

    const drawerStore = getDrawerStore();

    function clickUrl() {
        showSideBar.set(false);
    }

    $: {
        isSelected = isActive;
    }

    // Lifecycle
    const unsubscribePage = page.subscribe(pageData => {
        let basePath: string | string[] = pageData.url.pathname.split('/');
        if (basePath.length > 2) {
            basePath.shift();
            basePath = '/' + basePath.filter(isStringNotEmpty).join('/');
        } else {
            basePath = basePath[1];
        }

        if (!basePath) {
            return;
        }
        isActive = urlPath.includes(basePath);
    });

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<style lang="postcss">
    .s-item-link {
        @apply text-left text-sm py-2 text-black;
        @apply flex items-center px-2;
        padding-left: 3.3rem;
    }

    .s-item-highlight {
        @apply bg-teal-400 text-orange-900;
    }

    .s-item-link:hover,
    .s-item-link-active,
    .s-item-link:hover .s-item-highlight,
    .s-item-highlight:hover {
        @apply bg-teal-700 font-bold text-white;
    }

    .s-item-link-active {
        @apply opacity-75;
    }

    @media (min-width: 835px) {
        .s-item-link {
            padding-left: 2.75rem;
            @apply text-xs py-1;
        }
    }
</style>

<a href="{C9_SERVICE_GROUP_PAGES[category.name].urlPath}" on:click={clickUrl} class="s-item-link" class:s-item-link-active={isSelected}>
    <span class:s-item-highlight={category.lastTokenExists}>{ $t(category.name) }</span>
</a>
