<script lang="ts">
    import { page } from '$app/stores';
    import ConsentCookies from '$lib/components/cookies/ConsentCookies.svelte';
    import GoogleAnalytics from '$lib/components/google-analytics/GoogleAnalytics.svelte';
    import ConsentCookiesModal from '$lib/components/modals/ConsentCookiesModal.svelte';
    import SearchModal from '$lib/components/modals/SearchModal.svelte';
    import ShareModal from '$lib/components/modals/ShareModal.svelte';
    import SEO from '$lib/components/SEO/SEO.svelte';

    // Casa9 Components
    import Casa9AppBar from '$lib/components/template/C9AppBar.svelte';
    import C9Drawer from '$lib/components/template/C9Drawer.svelte';
    import C9Footer from '$lib/components/template/C9Footer.svelte';
    import PageHeaderDecorator from '$lib/components/template/PageHeaderDecorator.svelte';
    import C9SideBar from '$lib/components/template/sidebar/C9SideBar.svelte';
    import { initializeC9 } from '$lib/config';
    import { TOP_BANNER_ADS, TOP_BANNER_SIZES, TOP_BANNER_SIZES_MAPPING } from '$lib/config/config-ads';
    import { PageViewType } from '$lib/models/page.models';
    import { pageContentView } from '$lib/store/navigation.store';
    import { arrow, autoUpdate, computePosition, flip, offset, shift } from '@floating-ui/dom';
    import { GptSlot } from '@rigu/svelte-gpt';

    // Components & Utilities
    // Dependency: Floating UI
    import { AppShell, initializeStores, Modal, storePopup } from '@skeletonlabs/skeleton';
    import '../app.postcss';
    import { onDestroy } from 'svelte';

    // Registered list of Components for Modals
    const modalComponentRegistry = { //  : Record<string, C9Search> = {
        consentCookiesModal: { ref: ConsentCookiesModal },
        searchModal: { ref: SearchModal },
        shareModal: { ref: ShareModal },
    };

    initializeStores();
    storePopup.set({ computePosition, autoUpdate, offset, shift, flip, arrow });

    initializeC9();

    // let Ads;
    // onMount(async () => {
    //     Ads = (await import('$lib/components/google-ads/GoogleAds.svelte')).default;
    // });

    let isMapVisible: boolean = false;
    let unitCode = TOP_BANNER_ADS.default;

    $: {
        isMapVisible = $pageContentView === PageViewType.MAP;
    }

    const unsubscribePage = page.subscribe(pageData => {
        const routeId = pageData.route.id;
        if (!routeId) {
            return;
        }

        unitCode = TOP_BANNER_ADS[routeId] ?? TOP_BANNER_ADS.default;
    });

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<!--<style lang="postcss">-->
<!--    :global(.app-shell) {-->
<!--        @apply overflow-auto !important;-->
<!--        /*@apply h-screen;*/-->
<!--    }-->
<!--</style>-->

<SEO />
<ConsentCookies />
<GoogleAnalytics />

<C9Drawer />
<Modal components={modalComponentRegistry} />

<AppShell class="app-shell h-full xl:w-9/12 mx-0 xl:mx-auto min-h-screen {isMapVisible ? 'md:h-screen' : 'md:h-auto'}"
          regionPage="c9-page w-full flex-grow p-0 md:pl-3"
          scrollbarGutter="unset"
          slotHeader="sticky top-0 z-10 mb-1"
          slotPageContent="c9-page-content"
          slotFooter="hidden md:block w-full bg-gray-800 text-white text-xs text-left p-0"
          slotSidebarLeft="c9-sidebar-left overflow-y-hidden {isMapVisible ? 'md:relative md:h-full' : ''}">
    <svelte:fragment slot="header">
        <!-- layer for top banner -->
        <GptSlot networkCode="22377147561" {unitCode} size={TOP_BANNER_SIZES} sizeMapping={TOP_BANNER_SIZES_MAPPING} />
        <Casa9AppBar />
    </svelte:fragment>

    <!-- Sidebar (Left) -->
    <svelte:fragment slot="sidebarLeft">
        <C9SideBar class="hidden lg:grid h-0 min-h-full" />
    </svelte:fragment>

    <!-- Page Header -->
    <svelte:fragment slot="pageHeader">
        <PageHeaderDecorator />
    </svelte:fragment>

    <!-- Page Content -->
    <slot></slot>

    <!-- Footer -->
    <svelte:fragment slot="footer">
        <C9Footer />
    </svelte:fragment>
</AppShell>
