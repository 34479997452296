<script lang="ts">
    import { page } from '$app/stores';
    import { createEventDispatcher, onDestroy } from 'svelte';
    import type { Writable } from 'svelte/store';
    import { writable } from 'svelte/store';
    import { t } from "$lib/i18n/i18n-store";

    const dispatch = createEventDispatcher();

    export let link;

    // Local
    const active: Writable<boolean> = writable(false);

    function onClick(event) {
        dispatch('selectRoute', event);
    }

    const unsubscribePage = page.subscribe(pageData => {
        const basePath = pageData.url.pathname.split('/')[1];
        if (!basePath) {
            return;
        }
        active.set(link.path.includes(basePath));
    });

    onDestroy(() => {
        unsubscribePage();
    });
</script>

<style lang="postcss">
    .nav-route-link {
        @apply text-teal-900 no-underline;
    }

    .nav-route-link:hover {
        @apply text-teal-700 underline;
    }

    .nav-route-link.active,
    .nav-route-link.active:hover {
        @apply text-orange-700;
    }

    .nav-icon {
        @apply align-middle inline-block pl-5;
    }

    .nav-icon :global(svg)  {
        @apply w-5 h-5 !important;
    }

    .nav-icon-name :global(svg)  {
        @apply w-5 h-6;
    }
</style>

<a class="nav-route-link class:active={$active} inline-block {$$props.class ?? ''}" on:click={onClick} rel="prefetch" href={link.path}>
    {#if !!link.icon}
        <span class="nav-icon" class:nav-icon-name={!!link.name}>
            <svelte:component this={link.icon}/>
        </span>
    {/if}
    {#if !!link.name}
        <span class="align-bottom inline-flex pt-1 whitespace-nowrap" class:pl-5={link.icon}>
            {$t(link.name)}
        </span>
    {/if}
    <slot/>
</a>
