<script lang="ts">
    import MenuItem from '$lib/components/template/MenuItem.svelte';
    import { PageViewType } from '$lib/models/page.models';
    import { pageContentView } from '$lib/store/navigation.store';

    const footerLinks = {
        about: {path: '/despre-noi', name: 'nav.about'},
        contact: {path: '/contact', name: 'nav.contact'},
        terms: {path: '/termeni-si-conditii', name: 'nav.termeni-si-conditii'},
        privacy: {path: '/privacy', name: 'nav.privacy'},
        cookies: {path: '/cookies', name: 'nav.cookies'},
    };

    let viewType: PageViewType = PageViewType.OTHER;

    // $: viewType = $pageContentView;
</script>

<style lang="postcss">
    :global(.footer-link) {
        @apply text-teal-100 no-underline py-1 !important;
    }

    :global(.footer-link:hover) {
        @apply text-teal-300 underline !important;
    }
</style>

{#if viewType !== PageViewType.MAP}
    <div class="flex flex-row lg:justify-center lg:items-center w-full xl:w-9/12 mx-0 lg:mx-auto lg:px-3">
        <div class="flex flex-col lg:flex-row items-start w-1/3 lg:w-auto mx-0">
            <div class="inline-block lg:flex lg:flex-row">
                <p class="py-1 pl-6 md:px-4">
                    <MenuItem class="footer-link" link="{footerLinks.about}"/>
                </p>
                <p class="py-1 pl-6 md:px-4">
                    <MenuItem class="footer-link" link="{footerLinks.contact}"/>
                </p>
            </div>
        </div>
        <div class="flex flex-col lg:flex-row items-start w-2/3 lg:w-auto mx-0">
            <div class="inline-block lg:flex lg:flex-row">
                <p class="py-1 pr-4 md:px-4">
                    <MenuItem class="footer-link" link="{footerLinks.privacy}"/>
                </p>
                <p class="py-1 pr-4 md:px-4">
                    <MenuItem class="footer-link" link="{footerLinks.cookies}"/>
                </p>
            </div>
        </div>
    </div>
{/if}
